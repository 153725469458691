import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const AudioPlayer = makeShortcode("AudioPlayer");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-the-play-of-pattern",
      "style": {
        "position": "relative"
      }
    }}>{`🌈 The Play of Pattern`}<a parentName="h1" {...{
        "href": "#-the-play-of-pattern",
        "aria-label": " the play of pattern permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <StatusBanner warning mdxType="StatusBanner">
      <p>{`Kernel does not provide concrete answers. We are here to ask better questions.`}</p>
    </StatusBanner>
    <p>{`This is because there are no simple, one-size-fits-all answers to the questions we're interested in learning about and teaching. That is what makes them good questions.`}</p>
    <p><strong parentName="p">{`Our intention is to give you the framework to begin thinking in transformative ways about the most important questions raised by this Web 3 world we're building together.`}</strong></p>
    <p>{`Kernel Fellows will distinguish themselves by their ability to `}<strong parentName="p">{`identify patterns`}</strong>{`. `}</p>
    <p><img parentName="p" {...{
        "src": "/images/mod0-img/banner.jpg",
        "alt": "Banner"
      }}></img></p>
    <p>{`Pattern recognition is at the core of cognition. Becoming more conscious of patterns which already exist, as well as how you choose to interact with those in your local environment, is the single best thing you can do to improve your critical thinking skills.`}</p>
    <p>{`In particular, we hope Kernel Fellows begin thinking in terms of `}<strong parentName="p">{`complementary opposites.`}</strong>{`  `}</p>
    <p>{`Rather than using dualities like 'decentralization is good, centralization is bad'; or 'DeFi is innovative, banks are boring', this style of thinking recognizes that there is no good without evil; no attraction without repulsion, no North without South, no up without down. `}</p>
    <p>{`We could describe this as "quantum thought": being able to contemplate 0 and 1 simultaneously, as well as the spectrum of probability between. If you can cultivate honest humility, you may just learn how to hold all three in superposition without any grasping after certainty, and thereby make more balanced decisions.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Quantum thought is intended to identify the interplay of what kind of pattern?`}</p>
        <p>{`Complementary opposites.`}</p>
      </Card>
    </Flash>
    <p>{`Kernel Fellows `}<strong parentName="p">{`should have humility.`}</strong>{` We cannot be sure what the effect of our actions will be, we can only cultivate an acute observational awareness which will guide us toward making the right `}<em parentName="p">{`trade-offs`}</em>{`. `}</p>
    <p>{`If you want to change the world, the world will inevitably act back and change you. The results of Web 2 applications have made this clear. The greater our collective humility, the more gentle this exchange will be as Web 3 comes to life.`}</p>
    <p>{`The critical idea here is that thinking consciously about trade-offs, and developing the ability to hold many different probabilities in mind simultaneously without allowing personal bias to obscure your view of all possible futures, allows you to pick The Middle Way more often than not. `}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What characteristic has the greatest effect on choosing the most constructive trade-offs?`}</p>
        <p>{`Humility.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "the-honest-question",
      "style": {
        "position": "relative"
      }
    }}>{`The Honest Question`}<a parentName="h3" {...{
        "href": "#the-honest-question",
        "aria-label": "the honest question permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Those who have `}<strong parentName="p">{`positively`}</strong>{` changed the world did so because they learnt how to negotiate complexity, rather than impose their own will on things. They answered their own questions as honestly and directly as they could.`}</p>
    <p>{`Playing with pattern, and becoming conscious of the constant interplay of complementary opposites as a means of cultivating presence, leads us to one simple query:`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 What is the question to which you are the universe's answer?`}</p>
    </blockquote>
    <p>{`and its equally direct corollary:`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 How can you respond most honestly, accurately, and lovingly?`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/drive/folders/1-PURd0Re8wx7Kq6f2LtcEz0iC2X7Ry4t"
      }}><img parentName="a" {...{
          "src": "/images/mod0-img/jazz_pattern.png",
          "alt": "Contradictions - there's jazz going on!"
        }}></img></a></p>
    <h3 {...{
      "id": "listen",
      "style": {
        "position": "relative"
      }
    }}>{`Listen`}<a parentName="h3" {...{
        "href": "#listen",
        "aria-label": "listen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/M0E1+-+The+Play+of+Pattern.mp3" mdxType="AudioPlayer" />
    <h4 {...{
      "id": "divining-our-humanity",
      "style": {
        "position": "relative"
      }
    }}>{`Divining Our Humanity`}<a parentName="h4" {...{
        "href": "#divining-our-humanity",
        "aria-label": "divining our humanity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <List mdxType="List">
      <Link to="https://www.youtube.com/watch?v=xmlo0Lva5AU" mdxType="Link">
        <p>{`"Which of these most represents your true self?"`}</p>
      </Link>
      <Link to="https://thehumandivine.org/2021/02/07/fearful-symmetry-william-blake-and-sacred-geometry-by-rod-tweedy/amp/" mdxType="Link">
        <p>{`"The book of nature is written in the language of betweennesses"`}</p>
      </Link>
      <Link to="https://terebess.hu/zen/mesterek/Branching-Streams.pdf" mdxType="Link">
        <p>{`"The way to solve it is to walk on and on and on."`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      